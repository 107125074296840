@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Cinzel+Decorative:wght@400;700;900&display=swap');

.item-selections {
    display: flex;
    justify-content: space-evenly;
    width: 95%;
    margin: auto;
    flex-wrap: wrap-reverse;
}

p {
    margin: 0;
}

.item-list {
    text-align: left;
    max-width: 40%;
    display: block;
    justify-content: center;
    min-width: 444px;
}

.list-header {
    text-align: center;
    color: black;
    padding: 1vh .3em 1vh .3em;
    background: url('../PopluateCards/brass-texture.jpg') center no-repeat;
    background-size: cover;
    font-family: 'Cinzel Decorative', cursive;
    font-size: 1.5em;
    font-weight: bold;
    box-shadow: 5px 5px 15px 2px;
}

@media screen and (max-width: 935px) {
    .item-selections {
        display: block;
        width: 100%;
        flex-wrap: nowrap;
    }

    .item-list {
       max-width: 99%;
       width: auto;
       min-width: 0px;
       margin: auto;
    }
}