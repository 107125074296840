body {
  height: 100%;
  margin: 0;
  font-family: "Palatino Linotype", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}

header {
  color: white;
  background-color: black;
  padding: 20px 0 50px 0;
  top: 0;
  border-bottom: 7px ridge #4a4a4a;;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  padding-top: 50px;
  background: url('./wood-boards.jpg');
  background-size: cover;
  height: 100%;
  background-color: black;
}


@media screen and (orientation: landscape) {
  main {
    height: 100vh;
  }
}