@import url(https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Cinzel+Decorative:wght@400;700;900&display=swap);
body {
  height: 100%;
  margin: 0;
  font-family: "Palatino Linotype", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}

header {
  color: white;
  background-color: black;
  padding: 20px 0 50px 0;
  top: 0;
  border-bottom: 7px ridge #4a4a4a;;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  padding-top: 50px;
  background: url(/static/media/wood-boards.d4e113ac.jpg);
  background-size: cover;
  height: 100%;
  background-color: black;
}


@media screen and (orientation: landscape) {
  main {
    height: 100vh;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

main .itemCard {
    display: flex;
    width: 400px;
    margin: 10px 0;
    padding: 5px;
    cursor: pointer;
}

.itemCard .itemIcon img {
    width: 70px;
    height: auto;
}

.itemText {
    width: 100%;
    padding-right: 5px;
}

.itemText h3, .itemText p {
    margin: 0 0 0 10px;
    text-align: left;
}

.itemText div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.itemText div:nth-child(1) p {
    text-align: right;
}

.itemText p:nth-child(2) {
    font-style: italic;
}

@media screen and (max-width: 935px) {
   
    main .itemCard {
        display: flex;
        width: auto;
    }
}
.item-details {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: 4px black solid;
    text-align: left;
    padding: 10px 30px 20px 30px;
    background-color: white;
}

.descHeader {
    display: flex;
    justify-content: space-between;
}

.item-details h2 {
    font-weight: bold;
    font-size: 24px;
    margin: 0;
}

.item-details #item-type {
    font-style: italic;
    margin: 0;
}

.description {
    font-weight: bold;
    margin-top: 15px;
}

.empty {
    width: 35em;
}

@media screen and (max-width: 1600px) {
    .item-details {
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 935px) {
    
    .empty {
        position: fixed;
        top: 50%;
        transform: translate(0%, -50%);
        z-index: 10;
        width: 100%;
        height: 100%;
        background-color: rgb(51, 51, 51, .5);
        display: flex;
        justify-content: center;
        display: none;
    }

    .empty .item-details {
        box-shadow: none;
        position: relative;
        margin: auto;
        opacity: 1;
        width: 70%;
        padding-bottom: 10px;
    }

    .empty .item-details #close-button {
        text-align: center;
        font-size: 2em;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        margin: 10px auto 0px auto;
        background-color: rgb(43, 43, 43);
        color: white;
    }
}
.item-selections {
    display: flex;
    justify-content: space-evenly;
    width: 95%;
    margin: auto;
    flex-wrap: wrap-reverse;
}

p {
    margin: 0;
}

.item-list {
    text-align: left;
    max-width: 40%;
    display: block;
    justify-content: center;
    min-width: 444px;
}

.list-header {
    text-align: center;
    color: black;
    padding: 1vh .3em 1vh .3em;
    background: url(/static/media/brass-texture.639d2011.jpg) center no-repeat;
    background-size: cover;
    font-family: 'Cinzel Decorative', cursive;
    font-size: 1.5em;
    font-weight: bold;
    box-shadow: 5px 5px 15px 2px;
}

@media screen and (max-width: 935px) {
    .item-selections {
        display: block;
        width: 100%;
        flex-wrap: nowrap;
    }

    .item-list {
       max-width: 99%;
       width: auto;
       min-width: 0px;
       margin: auto;
    }
}
.title h1 {
    font-size: 48px;
    margin: 0;
}

.title p {
    font-size: 24px;
    margin: 0;
}

.cardDesign {
    background-color: whitesmoke;
    opacity: .95;
    border: 7px ridge #4a4a4a;
    box-shadow: 5px 5px 15px 2px;
    border-radius: 10px;
    min-width: 420px;
}

@media screen and (max-width: 935px) {
    .cardDesign {
        min-width: auto;
    }
}
